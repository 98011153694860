import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { palette } from "../_variables"
import { respondTo } from "../_respondTo"

const ActButton = styled(Link)`
  color: ${palette.primaryContrast};
  background-color: ${props =>
    props.primary ? palette.primary : palette.green};
  display: inline-block;
  text-decoration: none;
  text-align: center;
  margin: 30px auto 15px auto;
  padding: 0.2em 1em;
  border-radius: 0.5em;
  border-style: solid;

  :hover {
    color: ${palette.primaryContrastStong};
    background-color: ${props =>
      props.primary ? palette.secondary : palette.greenLight};
  }

  ${respondTo.md`
    
  `}
`

const ActionButton = ({ primary, toRef, external, children }) => {
  if (external) {
    return (
      <ActButton as="a" primary={primary} href={toRef}>
        {children}
      </ActButton>
    )
  }

  return (
    <ActButton primary={primary} to={toRef}>
      {children}
    </ActButton>
  )
}

export default ActionButton
