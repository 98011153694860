import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { palette } from "../_variables"
import { respondTo } from "../_respondTo"
import { FiArrowDown } from "react-icons/fi"




const ScrlButton = styled(Link)`

    color: ${palette.primaryContrast};
    background-color: rgba(0,42,108,0.8);
    display: inline-block;
    text-decoration:none;
    text-align: center;
    margin: 5px;
    padding: 5px;

    border-radius: 5px 5px 0 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 90%, 0 100%);

    :hover {
        background-color: rgb(46,126,207,0.8);     
      }

  ${respondTo.md`
    
  `}

`

const ScrollButton = ({ toRef }) => {

    return (

        <ScrlButton aria-label='scroll to next' to={toRef}><FiArrowDown></FiArrowDown></ScrlButton>

    )

}

export default ScrollButton